'use client';

import { useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import Link from 'next/link';
import Button from '@mui/material/Button';
import { CorporateFooterButton, CorporateFooterPanel, useCookieConfigModal } from 'ui/components';
import LogoFuca from '@public/logos/fuca.svg';
import SelloAnefp from '@public/icons/sello_anefp.svg';
import { useTranslation, type TranslationsDictionary } from '@lib/i18n';
import type { Settings } from '@lib/sanity/types';

export default function Footer({ config, dict }: { config?: Settings; dict: TranslationsDictionary }): JSX.Element {
  const [isCorporateFooterOpen, setIsCorporateFooterOpen] = useState(false);
  const { setIsConfigOpen } = useCookieConfigModal();
  const t = useTranslation(dict);
  const handleCorporateFooter = (): void => {
    setIsCorporateFooterOpen((prevState) => !prevState);
  };

  return (
    <>
      <footer className="flex justify-center w-full overflow-hidden px-4 pt-8 md:px-10 lg:px-16 bg-white text-black">
        <div className="flex flex-wrap w-full max-w-screen-lg gap-x-24">
          <div className="flex flex-row w-full justify-center">
            <div className="flex flex-col items-center w-full sm:w-[12rem]">
              <div className="flex justify-center h-10 md:h-10">
                <Link
                  aria-label={t('header.logoLinkAriaLabel')}
                  className="flex justify-center grow lg:grow-0 h-full focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[2px] z-10"
                  href="/"
                >
                  <LogoFuca className="fill-white h-[2.375rem]" />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row grow gap-x-20 pt-10 sm:pt-0 text-white w-full">
            <ul className="flex flex-col sm:flex-row md:flex-row lg:flex-row gap-5 lg:gap-8 sm:mt-6 lg:mt-10 justify-center w-full">
              {config?.mainMenuItems?.map((item) => (
                <li className="text-center sm:text-left text-primary" key={item.label}>
                  <Link
                    className="text-inherit text-lg md:text-base leading-6 font-normal focus-visible:outline rounded-sm focus:no-underline focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[2px]"
                    href={item.href}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-full flex justify-center items-center mt-6">
            <SelloAnefp alt="Sello Anefp" className="w-14 h-14" />
          </div>
          <div className="flex flex-col flex-wrap md:flex-row lg:flex-nowrap w-full pt-8 lg:pt-10 text-xxs md:text-xs font-normal">
            <div className="flex flex-col sm:flex-row w-full">
              <div className="flex w-full sm:w-auto pt-2 pb-1 sm:py-2 justify-center items-center text-center md:text-left lg:whitespace-nowrap leading-4">
                &copy;&nbsp;{t('footer.copyright')}
              </div>
              <ul className="flex flex-wrap grow sm:grow-0 lg:grow sm:ml-auto justify-center items-center sm:px-4 text-center lg:justify-center gap-x-4 lg:gap-x-8">
                {legalLinks(config?.legalMenuItems || [], t('footer.manageCookie'), setIsConfigOpen).map(
                  (legalLink) => (
                    <li key={legalLink.label}>
                      {legalLink.href ? (
                        <Link
                          className="inline-block text-inherit text-xxs md:text-xs whitespace-nowrap leading-[48px] tracking-tight lg:tracking-normal focus-visible:outline rounded-sm focus:no-underline focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-[2px]"
                          href={legalLink.href}
                        >
                          {legalLink.label}
                        </Link>
                      ) : (
                        <Button
                          className="p-0 text-inherit text-xxs md:text-xs font-normal whitespace-nowrap leading-[48px] normal-case rounded-sm hover:underline focus:no-underline"
                          disableFocusRipple
                          disableRipple
                          onClick={legalLink.onClick}
                        >
                          {legalLink.label}
                        </Button>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="flex w-full lg:w-auto justify-center items-end pt-2 lg:pt-0">
              <CorporateFooterButton
                className="bg-[#739A30] hover:bg-[#739A30]"
                isOpen={isCorporateFooterOpen}
                onClick={handleCorporateFooter}
                variant="white-logo"
              />
            </div>
          </div>
        </div>
      </footer>
      <CorporateFooterPanel bgColor="bg-[#739A30]" color="text-white" isOpen={isCorporateFooterOpen} />
    </>
  );
}

function legalLinks(
  config: { label: string; href?: string }[],
  cookieLabel: string,
  setIsCookieConfigModalOpen: Dispatch<SetStateAction<boolean>>
): { label: string; href?: string; onClick?: () => void }[] {
  return [
    ...config,
    {
      label: cookieLabel,
      onClick: () => {
        setIsCookieConfigModalOpen(true);
      },
    },
  ];
}
